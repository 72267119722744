document.addEventListener('DOMContentLoaded', () => {
    const body = document.body;
    let direction = 1;
    let position = 0;

    function animateGradient() {
        position += direction * 0.5;
        if (position >= 100 || position <= 0) {
            direction *= -1;
        }
        body.style.backgroundPosition = `${position}% 50%`;
        requestAnimationFrame(animateGradient);
    }

    animateGradient();
});
